@import url(https://fonts.googleapis.com/css2?family=Comfortaa:wght@300&display=swap);
* {
  outline: none;
  border: none;
}
html {
  background-color: rgb(246, 246, 246);
}
body {
  font-family: "Montserrat", arial, sans-serif;
  letter-spacing: inherit;
}

#root {
  background-color: rgb(246, 246, 246);
}

/*
Copyright 2017 The Archivo Black Project Authors (https://github.com/Omnibus-Type/ArchivoBlack)

This Font Software is licensed under the SIL Open Font License, Version 1.1.
This license is copied below, and is also available with a FAQ at: http://scripts.sil.org/OFL

—————————————————————————————-
SIL OPEN FONT LICENSE Version 1.1 - 26 February 2007
—————————————————————————————-
*/
/* latin-ext */
@font-face {
  font-family: "Archivo Black";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://img1.wsimg.com/gfonts/s/archivoblack/v10/HTxqL289NzCGg4MzN6KJ7eW6CYKF_i7y.woff2)
    format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: "Archivo Black";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://img1.wsimg.com/gfonts/s/archivoblack/v10/HTxqL289NzCGg4MzN6KJ7eW6CYyF_g.woff2)
    format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}

/*
Copyright 2011 The Montserrat Project Authors (https://github.com/JulietaUla/Montserrat)

This Font Software is licensed under the SIL Open Font License, Version 1.1.
This license is copied below, and is also available with a FAQ at: http://scripts.sil.org/OFL

—————————————————————————————-
SIL OPEN FONT LICENSE Version 1.1 - 26 February 2007
—————————————————————————————-
*/
/* cyrillic-ext */
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://img1.wsimg.com/gfonts/s/montserrat/v15/JTUSjIg1_i6t8kCHKm459WRhyzbi.woff2)
    format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F,
    U+FE2E-FE2F;
}

/* latin-ext */
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://img1.wsimg.com/gfonts/s/montserrat/v15/JTUSjIg1_i6t8kCHKm459Wdhyzbi.woff2)
    format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://img1.wsimg.com/gfonts/s/montserrat/v15/JTUSjIg1_i6t8kCHKm459Wlhyw.woff2)
    format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://img1.wsimg.com/gfonts/s/montserrat/v15/JTURjIg1_i6t8kCHKm45_dJE3gfD_u50.woff2)
    format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://img1.wsimg.com/gfonts/s/montserrat/v15/JTURjIg1_i6t8kCHKm45_dJE3gnD_g.woff2)
    format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}

/* @font-face {
  font-family: "Montserrat";
  src: url("https://img1.wsimg.com/gfonts/s/archivoblack/v10/HTxqL289NzCGg4MzN6KJ7eW6CYyF_g.woff2")
    format("woff2")
    url("https://img1.wsimg.com/gfonts/s/montserrat/v15/JTUSjIg1_i6t8kCHKm459Wlhyw.woff2")
    format("woff2")
    url("https://img1.wsimg.com/gfonts/s/montserrat/v15/JTURjIg1_i6t8kCHKm45_dJE3gnD_g.woff2")
    format("woff2")
    url("https://fonts.gstatic.com/s/roboto/v18/KFOmCnqEu92Fr1Mu4mxK.woff2")
    format("woff2");
} */

main {
  min-height: 80vh;
}

.Minter {
  text-align: right;
}

.formtext {
  position: relative;
  top: -7px;
  padding: 7px;
}
.App {
  font-family: sans-serif;
  text-align: center;
}
form {
  padding: 45px;
}
.hidden {
  display: none;
}

label {
  margin-top: 15px;
  margin-bottom: 5px;
}
button {
  margin-top: 30px;
}
.inline-errormsg {
  color: red;
  font-size: 12px;
}

.card:hover {
  -webkit-transform: scale(1.05);
          transform: scale(1.05);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.12), 0 4px 8px rgba(0, 0, 0, 0.06);
}
.card:hover > img {
  opacity: 0.3;
}

.opening {
  overflow: hidden;
}

footer {
  margin-top: 10%;
  margin-bottom: 5%;
}

.navbar-icon {
  opacity: 0.4;
}

.navbar-icon:hover {
  -webkit-transform: scale(1.05);
          transform: scale(1.05);
  opacity: 0.8;
}

.App {
  text-align: center;
  padding: 5%;
  font-family: "Montserrat", sans-serif;
}

.App-header {
  background-color: rgb(246, 246, 246);

  min-height: 50vh;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  font-size: calc(10px + 2vmin);
  color: rgb(0, 0, 0);
}

a {
  color: #61dafb;
}

.slick-arrow::before {
  font-size: 40px !important;
}

.slick-arrow.slick-prev::before {
  margin-left: -25px;
}

.slick-arrow.slick-next::before {
  margin-left: 15px;
}

.slick-wrapper {
  width: 100%;
  height: 100%;
  text-align: center;
}
/* the slides */
.slick-slide {
  margin: 10 10px;
}
/* the parent */
.slick-list {
  margin: 0 -10px;
  background-color: rgb(246, 246, 246);
}

.slick-slide > div {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  margin-left: 100px;
  margin-right: 50px;
}

.slick-prev:before,
.slick-next:before {
  color: black;
}

.slick-dots li button:before {
  font-size: 25px;
  line-height: 50px;
  padding-top: 10px;
}

.information-link {
  color: rgb(87, 87, 87);
}

.information-title {
  font-size: 24px;
  text-align: start;
  margin-left: 0;
  color: rgb(21, 21, 21);
}

.information-body > div > img {
  margin-left: 0 !important;
}

@media (min-width: 1201px) {
  .player-wrapper {
    position: relative;
    padding-top: 56.25%; /* Player ratio: 100 / (1280 / 720) */
  }

  .react-player {
    position: absolute;
    top: 0;
    left: 0;
  }
  .volume-button {
    background-repeat: no-repeat;
    width: 5em;
    height: 5em;
    position: absolute;
    top: 10px;
    margin-left: 20px;
    z-index: 1;
    cursor: pointer;
  }
  .video-wrapper {
    display: -webkit-flex !important;
    display: flex !important;
    -webkit-flex-direction: row !important;
            flex-direction: row !important;
  }
}

@media (max-width: 1200px) {
  .information-link {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: 180px;
  }
  .navbar-button {
    display: -webkit-flex !important;
    display: flex !important;
    text-align: start !important;
    padding-left: 7px !important;
  }
  .information-body {
    margin: 0 !important;
  }
  .volume-button {
    background-repeat: no-repeat;
    width: 5em;
    height: 5em;
    position: absolute;
    margin-left: 10px;
    margin-top: -10.5%;
    z-index: 1;
    cursor: pointer;
  }
  .slick-slide > div {
    width: 85%;
    margin: 10%;
  }

  .video-wrapper {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
  }

  .opening {
    visibility: hidden;
    color: red;
    background-color: green;
  }
  .navbar-toggler {
    margin-right: 20px;
  }
}

