.App {
  text-align: center;
  padding: 5%;
  font-family: "Montserrat", sans-serif;
}

.App-header {
  background-color: rgb(246, 246, 246);

  min-height: 50vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: rgb(0, 0, 0);
}

a {
  color: #61dafb;
}

.slick-arrow::before {
  font-size: 40px !important;
}

.slick-arrow.slick-prev::before {
  margin-left: -25px;
}

.slick-arrow.slick-next::before {
  margin-left: 15px;
}

.slick-wrapper {
  width: 100%;
  height: 100%;
  text-align: center;
}
/* the slides */
.slick-slide {
  margin: 10 10px;
}
/* the parent */
.slick-list {
  margin: 0 -10px;
  background-color: rgb(246, 246, 246);
}

.slick-slide > div {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-left: 100px;
  margin-right: 50px;
}

.slick-prev:before,
.slick-next:before {
  color: black;
}

.slick-dots li button:before {
  font-size: 25px;
  line-height: 50px;
  padding-top: 10px;
}

.information-link {
  color: rgb(87, 87, 87);
}

.information-title {
  font-size: 24px;
  text-align: start;
  margin-left: 0;
  color: rgb(21, 21, 21);
}

.information-body > div > img {
  margin-left: 0 !important;
}

@media (min-width: 1201px) {
  .player-wrapper {
    position: relative;
    padding-top: 56.25%; /* Player ratio: 100 / (1280 / 720) */
  }

  .react-player {
    position: absolute;
    top: 0;
    left: 0;
  }
  .volume-button {
    background-repeat: no-repeat;
    width: 5em;
    height: 5em;
    position: absolute;
    top: 10px;
    margin-left: 20px;
    z-index: 1;
    cursor: pointer;
  }
  .video-wrapper {
    display: flex !important;
    flex-direction: row !important;
  }
}

@media (max-width: 1200px) {
  .information-link {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: 180px;
  }
  .navbar-button {
    display: flex !important;
    text-align: start !important;
    padding-left: 7px !important;
  }
  .information-body {
    margin: 0 !important;
  }
  .volume-button {
    background-repeat: no-repeat;
    width: 5em;
    height: 5em;
    position: absolute;
    margin-left: 10px;
    margin-top: -10.5%;
    z-index: 1;
    cursor: pointer;
  }
  .slick-slide > div {
    width: 85%;
    margin: 10%;
  }

  .video-wrapper {
    display: flex;
    flex-direction: column;
  }

  .opening {
    visibility: hidden;
    color: red;
    background-color: green;
  }
  .navbar-toggler {
    margin-right: 20px;
  }
}
